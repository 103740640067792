import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../AuthContext';
import './LoginPage.css';

const LoginPage = () => {
  const { login } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    //await login({ email, password})
    //  .then(success => {
    //    if (!success) {throw new Error('Login Failed')};
    //    navigate('/');
    //  })
    //  .catch(() => alert('Please enter valid credentials')); 

    try {
      const success = await login({ email, password});
      if (success === true) {
        navigate('/');
      };
    } catch (e) {console.log(e)};
  };

  return (
    <div className="login-page">
      <h2>Welcome to Sibel</h2>
      <p>Please login to continue</p>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default LoginPage;
