import React, { useState, useEffect, useContext } from 'react';
import CompanyTabs from './components/CompanyTabs';
import Sidebar from './components/Sidebar';
import DocumentViewer from './components/DocumentViewer';
import DocumentUploader from './components/DocumentUploader';
import QueryModal from './components/QueryModal';  // Import the modal
import AuthContext from './AuthContext';
import './App.css';

function App() {
  const { logout } = useContext(AuthContext);
  const [selectedCompany, setSelectedCompany] = useState('Apple');  // Default company selection
  const [selectedDocument, setSelectedDocument] = useState(null);  // Document selection state
  const [activeTool, setActiveTool] = useState('upload');  // Default tool selection state is 'upload'
  const [isQueryModalOpen, setIsQueryModalOpen] = useState(false);  // Track modal state

  // Modal control
  const openQueryModal = () => setIsQueryModalOpen(true);
  const closeQueryModal = () => setIsQueryModalOpen(false);

  // Handle Command + K shortcut for modal
  useEffect(() => {
    const handleKeydown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        openQueryModal();
      }
      if (e.key === 'Escape') {
        closeQueryModal();
      }
    };
    window.addEventListener('keydown', handleKeydown);
    return () => window.removeEventListener('keydown', handleKeydown);
  }, []);

  const handleCompanySwitch = (company) => {
    setSelectedCompany(company);
    setSelectedDocument(null); // Reset document on company switch
  };

  const handleDocumentSelect = (doc) => {
    setSelectedDocument(doc); // Update document selection
  };

  return (
    <div className="app">
        <div className="main-page">
          <header className="header">
            <h1>Sibel - {selectedCompany} Analysis</h1>
            <button className="logout-btn" onClick={() => logout()}>
              Logout
            </button>
          </header>
          <div className="tabs-container">
            <CompanyTabs selectedCompany={selectedCompany} onCompanySwitch={handleCompanySwitch} />
          </div>
          <div className="content-container">
            <Sidebar activeTool={activeTool} setActiveTool={setActiveTool} />

            <div className="main-content">
              {activeTool === 'upload' && (
                selectedDocument ? (
                  <DocumentViewer
                    selectedDocument={selectedDocument}
                    selectedCompany={selectedCompany}
                  />
                ) : (
                  <div className="placeholder">
                    <DocumentUploader
                      selectedCompany={selectedCompany}
                      onDocumentSelect={handleDocumentSelect}
                    />
                  </div>
                )
              )}

              {activeTool === 'stocks' && (
                <div className="stocks-content">
                  <h2>Stocks Data for {selectedCompany}</h2>
                  <p>Display stock-related content and data for the company here.</p>
                </div>
              )}

              {activeTool === 'news' && (
                <div className="news-content">
                  <h2>Latest News for {selectedCompany}</h2>
                  <p>Display news articles or summaries related to the selected company here.</p>
                </div>
              )}
            </div>
          </div>
          <QueryModal show={isQueryModalOpen} onClose={closeQueryModal} />
        </div>
    </div>
  );
}

export default App;
