import React, { createContext, useState, useEffect } from 'react';
import config from './config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Move to /auth/...
  const authStatusURL = config.apiUrl + "/status"
  const loginURL = config.apiUrl + "/token"
  const logoutURL = config.apiUrl + "/logout"

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const response = await fetch(authStatusURL, {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error('HTTP Error');
      }

      const data = await response.json();
      setIsAuthenticated(data.isAuthenticated);
    } catch (e) {
      setIsAuthenticated(false);
    } finally { 
      setIsLoading(false);
    }
  };

  const login = async (credentials) => {
    const formData = new URLSearchParams();
    formData.append("username", credentials.email);
    formData.append("password", credentials.password);

    let success = false;
    try {
      const response = await fetch(loginURL, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
        body: formData.toString()
      });

      if (!response.ok) {
        throw new Error("Http Error");
      }

      setIsAuthenticated(true);
      success = true;
    } catch (e) {
      setIsAuthenticated(false);
      success = false
    }
    return success
  };

  const logout = async () => {
    try {
      const response = await fetch(logoutURL, {
        method: 'POST',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error("Http Error");
      }

      setIsAuthenticated(false);
    } catch (e) {
      setIsAuthenticated(false);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
