import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';  // Ensure CSS file is loaded, or remove this line if not used
import AppRouter from './AppRouter';
import { AuthProvider } from './AuthContext';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')  // Ensure this ID matches what's in your public/index.html
);
