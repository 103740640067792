import React, { useState } from 'react';
import './CompanyTabs.css';

const CompanyTabs = ({ selectedCompany, onCompanySwitch }) => {
  const initialCompanies = ['Apple', 'Alphabet', 'Tesla'];
  const magnificentSeven = ['Apple', 'Alphabet', 'Nvidia', 'Tesla', 'Meta'];

  const [companies, setCompanies] = useState(initialCompanies);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCompanySelect = (company) => {
    if (!companies.includes(company)) {
      setCompanies([...companies, company]);
    }
    onCompanySwitch(company);
    setIsDropdownOpen(false);
  };

  const handleCompanyRemove = (company, e) => {
    e.stopPropagation(); // Prevent click event from propagating to the parent
    setCompanies(companies.filter(c => c !== company));
  };

  return (
    <div className="company-tabs">
      {companies.map((company) => (
        <div
          key={company}
          className={`tab ${selectedCompany === company ? 'active' : ''}`}
          onClick={() => onCompanySwitch(company)} // Make the entire tab clickable
        >
          <span>{company}</span>
          <button className="close-btn" onClick={(e) => handleCompanyRemove(company, e)}>x</button>
        </div>
      ))}

      <div className="plus-button" onClick={handleDropdownToggle}>
        +
      </div>

      {isDropdownOpen && (
        <div className="company-tabs-dropdown">
          <h3>Select a Company</h3>
          {magnificentSeven.map((company) => (
            <div
              key={company}
              className="company-tabs-dropdown-item"
              onClick={() => handleCompanySelect(company)}
            >
              {company}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompanyTabs;
