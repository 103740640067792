import React, { useState } from 'react';
import './DocumentUploader.css';

const DocumentUploader = ({ selectedCompany, onDocumentSelect }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [expandedSections, setExpandedSections] = useState({
    SEC: false,
    Letters: false,
  });

  const documents = {
    Apple: {
      SEC: ['10-K', '8-K', 'S-1'],
      Letters: ['Investor Letter Q1 2023', 'Investor Letter Q2 2023'],
    },
    Alphabet: {
      SEC: ['10-K', '8-K', 'S-1'],
      Letters: ['Investor Letter Q1 2023', 'Investor Letter Q2 2023'],
    },
    Tesla: {
      SEC: ['10-K', '8-K', 'S-1'],
      Letters: ['Investor Letter Q1 2023', 'Investor Letter Q2 2023'],
    },
    Nvidia: {
      SEC: ['10-K', '8-K', 'S-1'],
      Letters: ['Investor Letter Q1 2023', 'Investor Letter Q2 2023'],
    },
    Meta: {
      SEC: ['10-K', '8-K', 'S-1'],
      Letters: ['Investor Letter Q1 2023', 'Investor Letter Q2 2023'],
    },
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  const handleDocumentClick = (doc) => {
    onDocumentSelect(doc);
    setDropdownVisible(false); // Hide dropdown after selection
  };

  return (
    <div className="document-uploader">
      <h2>Upload or Select Document</h2>
      <button className="type-button" onClick={toggleDropdown}>
        Select 
      </button>

      {dropdownVisible && (
        <div className="document-uploader-dropdown">
          <div className="dropdown-section">
            <h3 onClick={() => toggleSection('SEC')}>
              [-] SEC Filings
            </h3>
            {expandedSections.SEC && (
              <div className="dropdown-content">
                {documents[selectedCompany]?.SEC.map((doc) => (
                  <div
                    key={doc}
                    className="document-uploader-dropdown-item"
                    onClick={() => handleDocumentClick(doc)}
                  >
                    {doc}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="dropdown-section">
            <h3 onClick={() => toggleSection('Letters')}>
              [-] Investor Letters
            </h3>
            {expandedSections.Letters && (
              <div className="dropdown-content">
                {documents[selectedCompany]?.Letters.map((doc) => (
                  <div
                    key={doc}
                    className="document-uploader-dropdown-item"
                    onClick={() => handleDocumentClick(doc)}
                  >
                    {doc}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentUploader;
