import React from 'react';
import './QueryModal.css';

const QueryModal = ({ show, onClose }) => {
  if (!show) return null;  // Don't render the modal if `show` is false

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>  {/* Prevent click event bubbling */}
        <h2>Query</h2>
        <form>
          <input
            type="text"
            className="query-input"
            placeholder="Type your query here..."
          />
        </form>
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default QueryModal;
