import React, { useState, useEffect } from 'react';
import './DocumentViewer.css';
import config from '../config';

const DocumentViewer = ({ selectedDocument, selectedCompany }) => {
  const [query, setQuery] = useState('');
  const [responses, setResponses] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([
    { id: 'doc1', name: 'Form 10-K, 2024' },
    { id: 'doc2', name: 'Form 8-K' },
    { id: 'doc3', name: 'Form S-1' }
  ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Facts');
  const [factsData, setFactsData] = useState(null);
  const [statsData, setStatsData] = useState(null);

  const availableDocuments = [
    { id: 'doc4', name: 'Form 10-Q' },
    { id: 'doc5', name: 'Form 8-K (Supplemental)' },
    { id: 'doc6', name: 'Form 10-K, 2023' }
  ];

  // Fetch Facts JSON data
  useEffect(() => {
    if (activeTab === 'Facts') {
      fetch(`${config.apiUrl}/static/${selectedCompany.toLowerCase()}/facts_data.json`)
        .then(response => response.json())
        .then(data => setFactsData(data))
        .catch(error => console.error("Failed to load facts data:", error));
    }
  }, [activeTab, selectedCompany]);

  // Fetch Stats JSON data
  useEffect(() => {
    if (activeTab === 'Stats') {
      fetch(`${config.apiUrl}/static/${selectedCompany.toLowerCase()}/stats_data.json`)
        .then(response => response.json())
        .then(data => setStatsData(data))
        .catch(error => console.error("Failed to load stats data:", error));
    }
  }, [activeTab, selectedCompany]);

  const renderTable = (data, headers) => (
    <table>
      <thead>
        <tr>{headers.map(header => <th key={header}>{header}</th>)}</tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {headers.map(header => (
              <td key={header}>{row[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );

  const handleQuerySubmit = async (e) => {
    e.preventDefault();
    if (query.trim()) {
      setResponses((prevResponses) => [
        ...prevResponses,
        `Query: ${query}`,
        'Response: •'
      ]);
      setQuery('');

      let dotCount = 1;
      const dotInterval = setInterval(() => {
        setResponses((prevResponses) => {
          const updatedResponses = [...prevResponses];
          updatedResponses[updatedResponses.length - 1] = 
            `Response: ${'•'.repeat((dotCount % 3) + 1
          )}`; // Add 1-3 dots
          return updatedResponses;
        });
        dotCount++;
      }, 350)

      const data = { question: query };
      const response = await fetch(`${config.apiUrl}/query`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      const answer = response.ok 
        ? (await response.json()).answer 
        : "Oops something wen't wrong";

      clearInterval(dotInterval);

      setResponses((prevResponses) => [
        ...prevResponses.slice(0,-1),
        'Response: ' + answer
      ]);
    }
  };

  const removeDocument = (docId) => {
    setSelectedDocuments(selectedDocuments.filter(doc => doc.id !== docId));
  };

  const addDocument = (doc) => {
    if (!selectedDocuments.find(d => d.id === doc.id)) {
      setSelectedDocuments([...selectedDocuments, doc]);
      setIsDropdownOpen(false);
    }
  };

  return (
    <div className="grid-container">
      {/* Document viewer on the top left */}
      <div className="document-viewer">
        <iframe 
          src={`${config.apiUrl}/static/${selectedCompany.toLowerCase()}/latest.html`}
          title="10-K Document"
          className="document-iframe"
        ></iframe>
      </div>

      {/* Facts, Stats, and Notebook Tabs in the top right */}
      <div className="facts-container">
        <div className="tab-navigation">
          <button onClick={() => setActiveTab('Facts')} className={activeTab === 'Facts' ? 'active' : ''}>Facts</button>
          <button onClick={() => setActiveTab('Stats')} className={activeTab === 'Stats' ? 'active' : ''}>Stats</button>
          <button onClick={() => setActiveTab('Notebook')} className={activeTab === 'Notebook' ? 'active' : ''}>Notebook</button>
        </div>

        <div className="modular-content">
          {activeTab === 'Facts' && factsData && (
            <div className="modular-boxes">
              {factsData.sections.map((section, index) => (
                <div key={index} className="modular-box">
                  <h3>{section.title}</h3>
                  <ul className="bullet-points">
                    {section.content.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}

          {activeTab === 'Stats' && statsData && (
            <div className="stats-section">
              <h3>Product Sales by Category</h3>
              {renderTable(statsData.productSales, ["category", "2024", "change", "2023", "2022"])}

              <h3>Segment Operating Performance</h3>
              {renderTable(statsData.segmentPerformance, ["region", "2024", "change", "2023", "2022"])}

              <h3>Shareholder Equity and Paid-in Capital</h3>
              {renderTable(statsData.shareholderEquity, ["category", "2024", "2023", "2022"])}

              <h3>Stock Performance Comparison</h3>
              {renderTable(statsData.stockPerformance, ["year", "apple", "sp500", "dj_tech"])}
            </div>
          )}

          {activeTab === 'Notebook' && (
            <div className="notebook-placeholder">
              <p>Here will be the notebook</p>
            </div>
          )}
        </div>
      </div>

      {/* Workflow box in the bottom left */}
      <div className="workflow-box">
        <h3>Selected Documents</h3>
        {selectedDocuments.map((doc) => (
          <div key={doc.id} className="workflow-item">
            <input type="checkbox" id={doc.id} name="document" value={doc.name} />
            <label htmlFor={doc.id}>{doc.name}</label>
            <button className="remove-btn" onClick={() => removeDocument(doc.id)}>×</button>
          </div>
        ))}
        <button className="add-document-btn" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          Add document
        </button>
        {isDropdownOpen && (
          <div className="document-dropdown">
            {availableDocuments.map((doc) => (
              <div 
                key={doc.id} 
                className="document-dropdown-item"
                onClick={() => addDocument(doc)}
              >
                {doc.name}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Query box in the bottom right */}
      <div className="query-box">
        <div className="query-output">
          {responses.map((response, index) => (
            <div key={index} className="response-item">{response}</div>
          ))}
        </div>
        <form onSubmit={handleQuerySubmit} className="query-form">
          <input
            type="text"
            placeholder="Query"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="query-input"
          />
          <button type="submit" className="query-submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default DocumentViewer;
